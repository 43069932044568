.about-section {
  position: relative;
}

/* Existing styles */
.features-section {
  margin-top: 40px;
}

.home-content {
  margin-top: 40px;
}

.app-buttons {
  display: flex;
  gap: 10px; /* Add space between app buttons */
}

.about-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-container-full-width {
  width: 100vw; /* 100% of the viewport width */
  margin-left: calc(-50vw + 50%); /* Align to the full width */
  margin-right: calc(-50vw + 50%); /* Align to the full width */
  overflow: hidden;
}