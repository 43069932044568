/* Main Slider Section */
.slider-section {
  position: relative;
  overflow: hidden;
  transition: opacity 1s ease-in-out; /* Smooth fade-in and fade-out */
}

.fade-out {
  opacity: 0; /* Completely fade out */
}

.fade-in {
  opacity: 1; /* Fade back in */
}

/* Text Section */
.slider-title,
.slider-description {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Smooth fade-in and fade-out */
}

.fade-in .slider-title,
.fade-in .slider-description {
  opacity: 1; /* Fully visible */
}

/* Buttons */
.buttons-container {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in .buttons-container {
  opacity: 1; /* Fully visible */
}

/* Image Section */
.slider-image img {
  width: 100%;
  height: auto;
  opacity: 0; /* Start faded out */
  transition: opacity 1s ease-in-out; /* Smooth fade-in and fade-out */
}

.fade-in .slider-image img {
  opacity: 1; /* Fully visible */
}
.store-button {
  width: 50%;
  max-width: 150px;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s;
}