/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal content container */
.modal-content {
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 16px;
  max-width: 800px;
  max-height: 90vh;
}

/* Close button */
.close-button {
  position: absolute;
  top: 1px;
  right: 5px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 50px;
  color: #888;
  padding: 0;
}

.close-button span {
  line-height: 1;
}

.close-button:hover {
  color: #000;
}
@media (max-width: 768px) {
  .modal-content {
    padding: 8px;
    width: 95%;
  }
}

@media (max-width: 480px) {
  .modal-content {
    padding: 8px;
    width: 100%;
    max-height: 100vh;
    border-radius: 0;
  }

  .close-button {
    top: 5px;
    right: 5px;
    font-size: 20px;
  }
}
