.sticky-social-icons {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.social-icon {
  font-size: 24px;
  margin-bottom: 10px;
  color: #000;
  /* height: 30%;
  width: 10%; */
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
}

.insta-icon {
  margin-left: -9px;
  font-size: 24px;
  margin-bottom: 10px;
  color: #000;
  /* height: 30%;
  width: 10%; */
  height: 40px;
  width: 60px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .social-icon {
    height: 20px;
    width: 20px;
  }
  .insta-icon {
    margin-left: -5px;
    height: 20px;
    width: 30px;
  }
}
