.banner-container {
  background-color: #1F4872;
  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: center; 
  width: 100%; 
  height: 45px; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transform: perspective(1000px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3); 
  transform: translateZ(20px); 
  transition: transform 0.5s ease, box-shadow 0.5s ease; 
}
.banner-container:hover {
  transform: translateZ(40px);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
}

.banner-slide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  position: absolute;
  opacity: 1;
  transform: translateX(0);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.slide-out {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.text-container {
  flex: 1;
  text-align: left;
}

.banner-text {
  font-family: Arial, sans-serif;
  font-size: 1.5rem;
  color: #F9A989;
  margin: 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); 
}

.images-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.banner-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.banner-image.tilt-image {
  transform: rotate(-15deg);
}

.banner-image.tilt-image:hover {
  transform: rotate(0deg);
}

.banner-image:hover {
  transform: scale(1.1) rotate(5deg); 
}

@media (max-width: 768px) {
  .banner-container {
    width: 100%;
    height: 50px; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .banner-slide {
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .text-container {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    z-index: 10;
  }

  .banner-text {
    font-size: 1rem;
    color: #F9A989; 
    font-weight: bold; 
  }

  .images-container {
    justify-content: center;
    position: relative;
    z-index: 5;
  }

  .banner-image {
    width: 100px; 
    height: 100px;
    object-fit: contain;
  }
}

@media (max-width: 480px) { 
  .banner-container {
    height: 50px; 
  }

  .text-container {
    font-size: 1rem; 
  }

  .banner-text {
    font-weight: bold; 
  }

  .banner-image {
    width: 80px; 
    height: 80px;
  }
}
